.b1 {
  font-size: 10px;
}

#password-field{
  display: flex;
}

#password-field a{
  padding: 0px;
  font-size: 1rem;
  margin-left: 10%;
}

.b1{
  margin-left: 5%;
  margin-right: 5%;
  font-size: .7rem;
}