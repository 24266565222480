#load-animation {
    position: fixed;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 1;
  }

#fullScreenLoadingWrapper{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
}