/*
* Restaurant Box 
*/

.resbox{
    width: 320px;
    padding: 10px;
     border: 3px solid;
    margin: 0;
}

.resbox p {
    text-align: center;
}

#accept-terms{
    width: 2rem;
    height: 2rem;
}
#termsmodal{
    margin-left: 7%;
}

