.menuItemLabel{
    width: 60%;
}

/* Base for label styling */
.menuItemWrapper [type="checkbox"]:not(:checked),
.menuItemWrapper [type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.menuItemWrapper [type="checkbox"]:not(:checked) + label,
.menuItemWrapper [type="checkbox"]:checked + label {
  position: relative;
  padding-left: 2.45rem;
  cursor: pointer;
}

/* checkbox aspect */
.menuItemWrapper [type="checkbox"]:not(:checked) + label:before,
.menuItemWrapper [type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 2rem; height: 2rem;
  border: 2px solid #ccc;
  background: #fff;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}
/* checked mark aspect */
.menuItemWrapper [type="checkbox"]:not(:checked) + label:after,
.menuItemWrapper [type="checkbox"]:checked + label:after {
  content: '\2713\0020';
  position: absolute;
  top: .15rem; left: .22rem;
  font-size: 2.5rem;
  line-height: 0.8;
  color: #09ad7e;
  transition: all .2s;
  font-family: 'Lucida Sans Unicode', 'Arial Unicode MS', Arial;
}
/* checked mark aspect changes */
.menuItemWrapper [type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.menuItemWrapper [type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
.menuItemWrapper [type="checkbox"]:disabled:not(:checked) + label:before,
.menuItemWrapper [type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
.menuItemWrapper [type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
.menuItemWrapper [type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
.menuItemWrapper [type="checkbox"]:checked:focus + label:before,
.menuItemWrapper [type="checkbox"]:not(:checked):focus + label:before {
  border: 2px dotted blue;
}

/* hover style just for information */
label:hover:before {
  border: 2px solid #4778d9!important;
}