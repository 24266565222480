/* #dailyscroll{
    width: 1000px;
    height: 300px;
    overflow-x: scroll;
    white-space:nowrap
  }
  #dailyscroll{
      width:300px;
      height:100%;
  }

#dailyscroll{
    position: relative
} */

/* div.scrollmenu {
    background-color: #333;
    overflow: auto;
    white-space: nowrap;
  }
  
  div.scrollmenu a {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }
  
  div.scrollmenu a:hover {
    background-color: #777;
  } */

#my-carousel-inner {
  height: 10rem;
  min-height: 400px;
}

.mainText {
  position: absolute;
  top: 10rem;
  width: 60rem;
  color: #fff;
}

.mainText h1 {
  margin: 0;
  position: absolute;
  top: 5rem;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.mainText p {
  margin: 0;
  position: absolute;
  top: 9rem;
  left: 60%;
  margin-right: -90%;
  transform: translate(-90%, -90%);
}

div.box {
  margin: 0;
  position: absolute;
  top: 15rem;
  left: 100%;
  width: 55rem;
  margin-right: -100%;
  transform: translate(-100%, -100%);
}
