.page-footer .bg-muted {
    background-color: #1d3557;
}

.bg-muted {
    background-color: #1d3557;
}

/* footer.page-footer{
    display: block !important;
} */



.footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.footer-padding{
    width: 100%;
    height: 3.5rem;
    margin-top: 1rem;
}